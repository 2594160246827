import React from 'react';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import useCurrentWarehouseItemId from '../../../hooks/warehouse/useCurrentWarehouseItemId';
import WarehouseAuditLayout from '../../../components/Layout/Layouts/WarehouseAuditLayout';
import Radio from '../../../components/Form/Radio';
import FormikZodSchema from '../../../components/Form/FormikZodSchema';
import { z } from 'zod';
import FormikRadioGroup from '../../../components/Form/FormikRadioGroup';
import { RadioGroup } from '@headlessui/react';
import { Form } from 'formik';
import FormikSubmitButton from '../../../components/Form/FormikSubmitButton';
import { useNavigate } from 'react-router-dom';
import WarehouseAuditDefectsList from '../../../components/Warehouse/Audit/WarehouseAuditDefectsList';
import {
  fetchTransferItemById,
  postWarehouseAuditReject,
} from '../../../api/warehouseAudit';
import { useQuery, useQueryClient } from 'react-query';
import FormikErrorMessage from '../../../components/Form/FormikErrorMessage';
import { formatFormikError } from '../../../api/ApiError';
import { PartnerTransferItem } from '../../../api/partnerTransfers';

const schema = z.discriminatedUnion('approval', [
  z.object({
    approval: z.literal('none').refine(() => false, 'Required'),
  }),
  z.object({
    approval: z.literal('approved'),
  }),
  z.object({
    approval: z.literal('rejected'),
    defects: z
      .array(z.string(), {
        invalid_type_error: 'Required',
      })
      .min(1, 'At least one reason is required')
      .default([]),
  }),
]);

function auditStatusToSchemaValue(
  status?: PartnerTransferItem['status'],
): 'none' | 'approved' | 'rejected' {
  switch (status) {
    case 'AUDIT_ACCEPTED':
    case 'AUDIT_MODIFIED':
      return 'approved';

    case 'AUDIT_REJECTED':
      return 'rejected';

    default:
      return 'none';
  }
}

interface Props {}

const WarehouseAuditItemConditionPage: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const warehouseId = useCurrentWarehouseId();
  const itemId = useCurrentWarehouseItemId();

  const { data: item } = useQuery(
    ['partner-transfer-item', itemId],
    () => fetchTransferItemById(itemId!),
    {
      enabled: !!itemId,
      staleTime: 30000,
    },
  );

  return (
    <WarehouseAuditLayout exitButton warnOnExit>
      <div className="mb-6 text-xl">Review and Approve Item Condition</div>

      <p className="prose mb-4">
        Before accepting this item, please review the item&apos;s condition to
        confirm:
        <ul className="mb-2 list-disc space-y-1 pl-8">
          <li>
            There are no <b>dents</b> larger than a nickel
          </li>
          <li>
            There are no <b>cracks or webbing</b> anywhere on the bat
          </li>
          <li>
            The bat is not <b>bent</b>
          </li>
          <li>
            The <b>end cap</b> is not broken or loose
          </li>
          <li>
            The <b>grip tape</b> does not need to be replaced
          </li>
          <li>
            The bat does not <b>vibrate or rattle</b> when shaken
          </li>
        </ul>
      </p>

      <FormikZodSchema
        onSubmit={async (values, formikHelpers) => {
          try {
            if (values.approval === 'approved') {
              navigate(`/warehouse/${warehouseId}/audit/${itemId}/details`);
            } else if (values.approval === 'rejected') {
              const rejectResponse = await postWarehouseAuditReject(
                warehouseId!,
                itemId!,
                values.defects,
              );
              queryClient.invalidateQueries();
              navigate(`/warehouse/${warehouseId}/audit`);
            }
          } catch (e) {
            console.log(e);
            formikHelpers.setErrors(
              formatFormikError(e, {
                defaultMessage: 'Something went wrong...',
              }),
            );
          }
        }}
        schema={schema}
        initialValues={{
          approval: auditStatusToSchemaValue(item?.status),
        }}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <FormikRadioGroup name="approval" className="space-y-2">
              <RadioGroup.Option value="approved">
                {({ checked }) => (
                  <label className="flex items-center space-x-2">
                    <Radio checked={checked} />
                    <span>Approve, item is in good condition</span>
                  </label>
                )}
              </RadioGroup.Option>

              <RadioGroup.Option value="rejected">
                {({ checked }) => (
                  <label className="inline-flex items-center space-x-2">
                    <Radio checked={checked} />
                    <span>Reject, item is in poor condition</span>
                  </label>
                )}
              </RadioGroup.Option>
            </FormikRadioGroup>

            {values.approval === 'rejected' && (
              <div className="space-y-2 pl-6">
                <WarehouseAuditDefectsList />
              </div>
            )}

            <FormikErrorMessage />

            <div className="mt-6 flex space-x-4">
              <FormikSubmitButton>Continue</FormikSubmitButton>
            </div>
          </Form>
        )}
      </FormikZodSchema>
    </WarehouseAuditLayout>
  );
};

export default WarehouseAuditItemConditionPage;
