import axios from 'axios';
import { getBaseUrl } from './axiosInitialization';
import { ApiResponse } from './intf/generic';
import { ListingItem } from './intf/item';

export interface PartnerTransfer {
  id: string;
  items_count: number;
  accepted_count: number;
  delivered_count: number;
  reviewed_count: number;
  shipments_count: number;
  shipped_count: number;
  label_url: string;
  tracking_url: string;
  shipped_at: string; // Date
  delivered_at: string; // Date
  reviewed_at: string; // Date
  created_at: string; // Date
  created_by?: string;
  items?: PartnerTransferItem[];
}

export interface PartnerFullTransfer extends PartnerTransfer {
  items: PartnerTransferItem[];
  shipments: PartnerTransferShipment[];
}

export interface PartnerTransferShipment {
  id: string;
  created_at: string; // Date
  shipped_at?: string; // Date
  delivered_at?: string; // Date
  reviewed_at?: string; // Date
  shipment_status: 'PENDING_SHIPMENT' | 'IN_TRANSIT' | 'DELIVERED';
  tracking_number?: string;
  tracking_url?: string;
  label_url?: string;
}

export interface PartnerTransferItem {
  id: string;
  item_id: string;
  transfer_id: string;
  created_by: string;
  created_at: string; // Date
  audited_by?: string;
  price_variance?: number;
  details_changed_count?: number;
  audited_at?: string; // Date
  status:
    | 'AUDIT_IN_PROGRESS'
    | 'AUDIT_ACCEPTED'
    | 'AUDIT_REJECTED'
    | 'AUDIT_MODIFIED'
    | string;
  name: string;
  tag: string;
  original_data: ListingItem;
  reviewed_data?: ListingItem; // optional because it's not always reviewed
}

export interface PartnerItemToTransfer {
  item_id: string;
  name: string;
  details: Record<string, any>;
  tag: string;
  quoted_by: string;
  quote_id: string;
  quote_completed_at: string; // Date
}

export const fetchTransferHistory = async () => {
  const { data } = await axios.get<ApiResponse<PartnerTransfer[]>>(
    '/warehouse/v1/transfers',
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Failed to fetch items to transfer');
};

export interface FetchTransferHistoryItemsParams {
  sort?: keyof PartnerTransferItem;
  order?: 'asc' | 'desc';
  page?: string;
  size?: string;
}

export const fetchTransferHistoryItems = async (
  params: FetchTransferHistoryItemsParams = {},
) => {
  const { data } = await axios.get<ApiResponse<PartnerTransferItem[]>>(
    '/warehouse/v1/transfers/items',
    {
      params,
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data) {
    return data;
  }

  throw new Error('Failed to fetch items to transfer');
};

export const fetchItemsToTransfer = async () => {
  const { data } = await axios.get<ApiResponse<PartnerItemToTransfer[]>>(
    '/trade-in/v1/in-store/events/items-to-transfer',
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Failed to fetch items to transfer');
};

export const fetchTransferById = async (id: string) => {
  const { data } = await axios.get<ApiResponse<PartnerFullTransfer>>(
    `/warehouse/v1/transfers/${id}`,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Failed to fetch transfer');
};

export const postCreateTransfer = async (
  boxCount: string | number,
  items: string[],
) => {
  const { data } = await axios.post<ApiResponse<PartnerTransfer>>(
    '/warehouse/v1/transfers',
    {
      box_count: boxCount,
      items: items,
    },
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Failed to create transfer');
};
