import React from 'react';
import PageLayout from '../../../components/Layout/PageLayout';
import { useQuery } from 'react-query';
import {
  fetchItemsToTransfer,
  fetchTransferHistory,
} from '../../../api/partnerTransfers';
import PageHeader from '../../../components/Layout/PageHeader';
import pluralize from '../../../utils/pluralize';
import Message from '../../../components/Form/Message';
import { getErrorMessage } from '../../../api/ApiError';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Form/Button';
import TransfersTable from '../../../components/Partner/TransfersTable';
import { fireVisitedAllTransfers } from '../../../services/analytics/partner/dashboard';
import { useMount } from 'react-use';

interface Props {}

const PartnerDashboardTransfersPage: React.FC<Props> = () => {
  useMount(fireVisitedAllTransfers);

  const { data: transfers, error: transfersError } = useQuery(
    ['transfers'],
    fetchTransferHistory,
  );
  const { data: itemsToTransfer, error: itemsToTransferError } = useQuery(
    ['items-to-transfer'],
    fetchItemsToTransfer,
  );

  return (
    <PageLayout
      header={
        <>
          <PageHeader>Manage Shipments</PageHeader>
        </>
      }
    >
      {!!transfersError && (
        <Message error>
          Something went wrong: {getErrorMessage(transfersError)}
        </Message>
      )}

      {itemsToTransfer && (
        <section aria-label="Items to Ship" className="mb-6 rounded border p-4">
          {!!itemsToTransferError && (
            <Message error>{getErrorMessage(itemsToTransferError)}</Message>
          )}

          <div className="flex items-center">
            <div className="flex-1 text-lg">
              <span className="font-semibold">{itemsToTransfer.length}</span>{' '}
              {pluralize(itemsToTransfer.length, 'Item', 'Items')} to Ship
            </div>

            <Link to="/partner/dashboard/transfers/new">
              <Button>Create New Shipment</Button>
            </Link>
          </div>
        </section>
      )}

      {transfers && (
        <section aria-label="History" className="mb-6 rounded border p-4">
          <div className="mb-4 flex items-center">
            <div className="mr-8 flex-1 text-lg">History</div>
          </div>

          {transfers.length === 0 && (
            <div className="mx-auto my-8 font-semibold text-slate-green-500">
              No results
            </div>
          )}

          {transfers.length > 0 && <TransfersTable transfers={transfers} />}
        </section>
      )}
    </PageLayout>
  );
};

export default PartnerDashboardTransfersPage;
