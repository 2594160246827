import qs from 'qs';

/**
 * Returns a query string prepended by a "?"
 */
export default function qsStringify<
  Q extends Record<string, any> | undefined | null,
>(query?: Q, options: qs.IStringifyOptions = {}) {
  if (!query) {
    return '';
  }

  return qs.stringify(query, {
    addQueryPrefix: true,
    arrayFormat: 'brackets',
    skipNulls: true,
    allowDots: true,
    encode: false,
    ...options,
  });
}
