import React from 'react';
import WarehouseAuditLayout from '../../../components/Layout/Layouts/WarehouseAuditLayout';
import WarehouseAuditItemsTable from '../../../components/Warehouse/Audit/WarehouseAuditItemsTable';
import { Button } from '../../../components/Form/Button';
import { Link } from 'react-router-dom';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';

interface Props {}

const WarehouseAuditMainPage: React.FC<Props> = () => {
  const warehouseId = useCurrentWarehouseId();

  return (
    <WarehouseAuditLayout
      headerRightContent={
        <>
          <Link
            className="flex-shrink-0"
            to={`/warehouse/${warehouseId}/audit/scan`}
          >
            <Button>Scan Item</Button>
          </Link>
        </>
      }
    >
      <WarehouseAuditItemsTable />
    </WarehouseAuditLayout>
  );
};

export default WarehouseAuditMainPage;
