import React from 'react';
import { flexRender, Row, Table } from '@tanstack/react-table';
import classNames from 'classnames';
import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
} from '@heroicons/react/24/outline';

function SimpleReactTable<T>({
  onRowClick,
  table,
}: {
  onRowClick?: (row: Row<T>) => void;
  table: Table<T>;
}) {
  const footer = table.getFooterGroups();

  const sortIconCx = 'text-green-500 ml-1.5 h-4 w-4 inline-block';

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full">
        <thead className="border-b border-grey-200">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  className="whitespace-nowrap py-3 pl-4 pr-3 text-left text-sm font-semibold sm:pl-3"
                  colSpan={header.colSpan}
                  onClick={() => {
                    if (header.column.getCanSort()) {
                      header.column.toggleSorting();
                    }
                  }}
                  style={{
                    width: header.column.columnDef.size,
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                  {{
                    asc: <BarsArrowUpIcon className={sortIconCx} />,
                    desc: <BarsArrowDownIcon className={sortIconCx} />,
                  }[header.column.getIsSorted() as string] ?? null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => (
            <tr
              key={row.id}
              className={classNames('transition-colors duration-75', {
                'hover:bg-grey-100': !row.getIsSelected(),
                'bg-green-200': row.getIsSelected(),
                'bg-grey-50': !row.getIsSelected() && index % 2 === 0,
                'cursor-pointer': !!onRowClick,
              })}
              onClick={() => onRowClick?.(row)}
            >
              {row.getVisibleCells().map(cell => (
                <td
                  key={cell.id}
                  className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-3"
                  style={{
                    width: cell.column.columnDef.size,
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {footer.map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(footer => (
                <th
                  key={footer.id}
                  className="whitespace-nowrap py-3 pl-4 pr-3 text-left text-sm font-semibold sm:pl-3"
                  colSpan={footer.colSpan}
                  style={{
                    width: footer.column.columnDef.size,
                  }}
                >
                  {flexRender(
                    footer.column.columnDef.footer,
                    footer.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
}

export default SimpleReactTable;
