import React, { useState } from 'react';
import WarehouseAuditLayout from '../../../components/Layout/Layouts/WarehouseAuditLayout';
import Message from '../../../components/Form/Message';
import { postWarehouseAuditScan } from '../../../api/warehouseAudit';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import { useNavigate } from 'react-router-dom';
import Scanner from '../../../components/Scanner/Scanner';
import TagInput from '../../../components/Partner/TagInput';
import Field from '../../../components/Form/Field';
import FieldLabel from '../../../components/Form/FieldLabel';
import FieldHint from '../../../components/Form/FieldHint';
import { Button } from '../../../components/Form/Button';
import { getErrorMessage } from '../../../api/ApiError';

interface Props {}

const WarehouseAuditScanPage: React.FC<Props> = () => {
  const warehouseId = useCurrentWarehouseId();
  const navigate = useNavigate();
  const [error, setError] = useState<null | Error>(null);
  const [tagId, setTagId] = useState('');

  async function handleSubmit(tag: string) {
    setError(null);

    try {
      const item = await postWarehouseAuditScan(
        warehouseId!,
        tag.replace(/-/g, ''),
      );

      if (item) {
        navigate(`/warehouse/${warehouseId}/audit/${item.id}/condition`);
      } else {
        throw new Error('Item not found');
      }
    } catch (e) {
      setError(e as Error);
    }
  }

  return (
    <WarehouseAuditLayout exitButton>
      <div className="mx-auto max-w-screen-sm">
        <Scanner
          mode="qr"
          onScan={async data => {
            const url = new URL(data);
            const lastPath = url.pathname.split('/').pop();

            await handleSubmit(lastPath!);
          }}
        />
      </div>

      <form
        className="my-4 mx-auto max-w-sm"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit(tagId);
        }}
      >
        <Field>
          <FieldLabel htmlFor="tag">Tag</FieldLabel>
          <div className="flex space-x-4">
            <TagInput
              id="tag"
              value={tagId}
              onChange={e => setTagId(e.target.value)}
            />
            <Button type="submit">Submit</Button>
          </div>
          <FieldHint>
            If the scanner isn't working, enter the tag here.
          </FieldHint>
        </Field>
      </form>

      {error && (
        <div className="mx-auto max-w-sm">
          <Message error>{getErrorMessage(error)}</Message>
        </div>
      )}
    </WarehouseAuditLayout>
  );
};

export default WarehouseAuditScanPage;
