import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { getErrorMessage } from '../../api/ApiError';
import selectEnv from '../../utils/selectEnv';

interface Props {
  listing: { legacy_id?: string | number };

  // Don't limit the height/width
  unsized?: boolean;
}

const ItemListingQrCode: React.FC<Props> = ({ listing, unsized }) => {
  const [error, setError] = useState<Error | null>(null);
  const [dataUrl, setDataUrl] = useState('');

  function generateCode() {
    QRCode.toDataURL(
      `${selectEnv(
        'https://sidelineswap.com',
        'https://staging.sidelineswap.com',
      )}/items/${listing.legacy_id}`,
      {
        margin: 0,
      },
      (error, url) => {
        if (error) {
          setError(error);
        }
        setDataUrl(url);
      },
    );
  }

  useEffect(() => {
    if (listing.legacy_id) {
      generateCode();
    }
  }, [listing.legacy_id]);

  if (error) {
    return (
      <div
        className="text-red-500"
        style={{
          width: unsized ? undefined : '0.7in',
        }}
      >
        QR Code failed: {getErrorMessage(error)}.{' '}
        <button className="underline" onClick={generateCode}>
          Try again
        </button>
      </div>
    );
  }

  if (!listing.legacy_id) {
    return (
      <div
        style={{
          width: '0.7in',
        }}
        className="flex h-full items-center justify-center border"
      >
        <i>No ID...</i>
      </div>
    );
  }

  return (
    <img
      src={dataUrl}
      style={{
        width: unsized ? '100%' : '0.7in',
        height: '100%',
      }}
    />
  );
};

export default ItemListingQrCode;
